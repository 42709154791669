import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import { Link } from 'gatsby';
import Image from '../../components/Image';
import Quicklinks from '../../components/Quicklinks';
import StudentQuote from '../../components/StudentQuote';

const EventGalleryPhotos = () => (
  <Layout
    title="Inside the Challenge"
    className="annual-challenge event-gallery"
  >
    <div className="hero-container">
      <div className="hero-container__gradient bg-gradient-orange"></div>
      <div className="hero-container__titleImage">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          <li className="breadcrumb">
            <Link to="../../annual-challenge/about-the-challenge/">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb active">Inside the Challenge</li>
        </ul>
        <h1 className="page-title">Inside the Challenge</h1>
        <Image filename="hero-events.png" className="hero-image" />
      </div>
    </div>
    {/* Event Gallery Intro */}
    <Section>
      <Container>
        <Row>
          <Column size={8} offset={2} className="global-intro">
            <h2>Welcome to the 3M Young Scientist Challenge</h2>
            <h4>
              Go inside the premier middle school science competition with a
              collection of multimedia resources that bring to life the
              challenge journey. Hear firsthand from students and 3M team
              members who have participated in the 3M Young Scientist Challenge
              to learn more about what it takes to become America’s Top Young
              Scientist.
            </h4>
          </Column>
        </Row>
        <Row>
          <Column size={10}>
            <ul className="filter-tabs annual-challenge">
              <li className="filter-tabs__tab">
                <Link to="../event-gallery-videos">Videos</Link>
              </li>
              <li className="filter-tabs__tab selected">
                <Link to="#">Photos</Link>
              </li>
            </ul>
          </Column>
        </Row>
        <Row>
          <Column size={10}>
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#',
                  label: 'Live Events',
                },
                {
                  target: '#',
                  label: 'Highlights',
                },
                {
                  target: '#',
                  label: 'Webinars',
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Live Events */}
    <Section className="mb-6" id="live-events">
      <Container>
        <Row className="gallery-heading">
          <h2>Photo Galleries Coming Soon</h2>
        </Row>
      </Container>
    </Section>
    {/* Student Stories Quote */}
    <Section className="student-stories">
      <StudentQuote
        image="image-moitri-santra-quote.png"
        name="Moitri Santra"
        year="2021 3M Young Scientist Challenge Finalist "
        body="This challenge was a fantastic opportunity to integrate myself more into the
        world of brain computer interfaces and how they can be applied in early diagnosis
        and treatment."
        path="/annual-challenge/finalists-mentors-judges/finalists/moitri-santra"
        classNames="bg-gradient-orange cta__link no-arrow"
        calloutText="Learn what sparked Moitri’s journey"
      ></StudentQuote>
    </Section>
  </Layout>
);

export default EventGalleryPhotos;
